<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/OrderConfig/OrderSupplementaryEntry"
            >订单补录 /
          </router-link>
          <span class="crumbs_item crumbs_last">{{orderId?'详情':'新建'}}</span>
        </span>
      </div>
    </div>

    <div class="headTitle">
      <p>账号信息</p>
    </div>
    <div>
      <a-row class="rows">
        <a-col class="left required" span="4">用户类型</a-col>
        <a-col :span="3">
          <a-select placeholder="用户类型" style="width: 100%" @change="changeUserType" v-model="form.userType">
            <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
            <a-select-option value="1"> 个人 </a-select-option>
            <a-select-option value="2"> 机构 </a-select-option>
          </a-select>
        </a-col>
        <a-col class="left required" span="4">账号信息</a-col>
        <a-col :span="8">
          <a-select
            style="width: 100%"
            show-search
            v-model="form.userId"
            :placeholder="form.userType == 1? '请输入姓名/手机号':'请输入机构名称/机构码'"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleSearch"
            @change="handleChange"
          >
            <a-select-option v-for="item in userList" :key="item.userId">
              <template v-if="form.userType == 1">{{ item.mobile }} / {{ item.name }}</template>
              <template v-else>{{ item.name }} / {{ item.idNo }} / {{ item.contactName }}</template>
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </div>

    <div class="headTitle">
      <p>商品清单</p>
      <p class="func" @click="addShop" v-if="!orderId">添加商品+</p>
    </div>
    <a-table
      class="table-template"
      :rowKey="(item,index) => index"
      :columns="columns"
      :data-source="tableData"
      :scroll="{ x: true }"
      :pagination="false"
    >
      <template slot="index" slot-scope="item, row, index">
        {{ index + 1 }}
        <!-- {{ index + 1 }} -->
      </template>
      <template slot="productType" slot-scope="text">
        {{ retproductType(text) }}
      </template>
      <template slot="isGift" slot-scope="text,item">
        {{ text || item.type == 2?'赠品':'非赠品' }}
      </template>
      <template slot="channelName" slot-scope="text">
        {{ text ? text : '-' }}
      </template>
      <template slot="payPrice"  slot-scope="text, item">
        <a-input-number style="width: 200px;" v-model="item.payPrice" @change="value => priceChange(value, item)" placeholder="请输入金额" />
      </template>
      <template slot="operation" slot-scope="text,item,index">
        <!-- <span class="blueText" @click="editShop(index)">
          <span>修改</span>
        </span> -->
        <span class="blueText" @click="setGift(index)" v-if="!orderId && !item.isGift && form.payMethod != 6 && form.userType == 1">
          <span>设为{{item.type == 2?'非':''}}赠品 |</span>
        </span>
        <span class="blueText" @click="delShop(index,item.productId)" v-if="!orderId && !item.isGift">
          <span> 删除</span>
        </span>
        <span class="blueText" @click="channelIndex = index ,markVisible = true" v-if="!orderId && !item.isGift && form.userType == '1' && (item.productType == 1 || item.productType == 2 || item.productType == 3)">
          <span> | 渠道标记</span>
        </span>
        <template v-if="orderId">-</template>
      </template>
      <template slot="footer" v-if="tableData.length">
        <!-- <a-checkbox @change="onChange" :checked="isChecked" class="allSellect">
          全选logisticsStatus
        </a-checkbox> -->
        <!-- <div
          class="left"
        >
          
        </div> -->
        <div class="right">
          <p>
            商品总价：{{allPrice}}元
          </p>
        </div>
      </template>
    </a-table>

    <div class="headTitle" v-if="havebooks">
      <p>收货地址</p>
      <a-button style="margin-left: 10px;" type="primary" class="btn" :loading="btnLoading" @click="getAddressList()">选择地址</a-button>
    </div>
    <div v-if="havebooks">
      <a-row class="rows">
        <a-col span="3" class="left required">收货人</a-col>
        <a-col span="8" class="right">
          <a-input v-model="addressObj.consignee" placeholder="请输入收货人" />
        </a-col>
        <a-col span="3" class="left required">手机号码</a-col>
        <a-col span="9" class="right">
          <a-input v-model="addressObj.phone" placeholder="请输入手机号码" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="3" class="left required">收货地址</a-col>
        <a-col span="8" class="right">
          <Province
            style="width: 100%"
            @regionCity="FunProvince"
            :value="addressObj.shippingAddressValue"
            ref="childProvince"
          ></Province>
        </a-col>
        <a-col span="3" class="left required">详细地址</a-col>
        <a-col span="9" class="right">
          <a-input v-model="addressObj.address" placeholder="请输入详细地址" />
        </a-col>
      </a-row>
      <a-row class="rows" v-if="!orderId">
        <a-col span="3"></a-col><a-button type="primary" class="btn" :loading="btnLoading" @click="pushData" v-hasPermi="['logistics:address:edit']">{{form.receiveId?'修改':'保存'}}</a-button>
      </a-row>
    </div>

    <div class="headTitle">
      <p>支付信息</p>
    </div>
    <div>
      <a-row class="rows">
        <a-col class="left required" span="3">支付方式</a-col>
        <a-col :span="5">
          <a-select placeholder="支付方式" style="width: 100%" v-model="form.payMethod" @change="changePayMethod">
            <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
            <a-select-option v-for="item in payType" :key="item.key">{{item.value}}</a-select-option>
          </a-select>
        </a-col>
        <a-col class="left required" span="3">订单总额</a-col>
        <a-col :span="5">
          <a-input-number
            style="width: 100%"
            placeholder="请输入订单总额"
            v-model="form.payPrice"
            :min="form.payMethod == 6?0:0.01"
            :disabled="form.payMethod == 6"
          />
        </a-col>
        <a-col class="left" span="3">支付交易号</a-col>
        <a-col :span="5">
          <a-input placeholder="请输入交易号" v-model="form.tradeId" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="3">支付日期</a-col>
        <a-col :span="5">
          <a-date-picker style="width: 100%;" v-model="form.paymentTime" show-time valueFormat="YYYY-MM-DD HH:mm:ss"/>
        </a-col>
        <a-col class="left required" span="3">收款方</a-col>
        <a-col :span="5">
          <a-select placeholder="请选择收款方" style="width: 100%" v-model="form.billingAgency" @change="billingChange">
            <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
            <a-select-option :key="1">元道</a-select-option>
            <a-select-option :key="2">出版社</a-select-option>
          </a-select>
        </a-col>
        <a-col class="left" span="3">渠道来源</a-col>
        <a-col :span="5">
          <a-select placeholder="请选择渠道来源（非必填）" style="width: 100%" v-model="form.receivingSource">
            <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
            <a-select-option key="小鹅通">小鹅通</a-select-option>
            <a-select-option key="视频号">视频号</a-select-option>
            <a-select-option key="企微-元道">企微-元道</a-select-option>
            <a-select-option key="企微-出版社">企微-出版社</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row class="rows" v-if="!this.orderId">
        <a-col class="left" span="3">总付款额</a-col>
        <a-col :span="5">
          <a-input placeholder="当一笔回款需补录多笔订单时，请输入回款总额" v-model="form.receiptAmount" />
        </a-col>
        <a-col class="left required" span="3" v-show="form.payMethod == 3">转账户名</a-col>
        <a-col :span="5"  v-show="form.payMethod == 3">
          <a-input placeholder="请输入转账户名" v-model="form.accountName" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left" span="3">支付凭证</a-col>
        <a-col :span="5">
          <a-upload
            list-type="picture-card"
            :showUploadList="false"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            :beforeUpload="beforeUpload"
            :loading="loadingPicture"
            :customRequest="uploadPicture"
          >
            <img
              v-if="form.payVoucher"
              style="max-width: 130px"
              :src="form.payVoucher"
              title=""
              alt=""
            />
            <div v-else>
              <a-icon :type="loadingPicture ? 'loading' : 'plus'" />
              <div class="uploadText">点击上传图片</div>
            </div>
          </a-upload>
        </a-col>
        <a-col class="left required" span="3">是否为换课订单</a-col>
        <a-col :span="5">
          <a-radio-group v-model="form.changeProductFlag">
            <a-radio :style="radioStyle" :value="0">
              否
            </a-radio>
            <a-radio :style="radioStyle" :value="2">
              是
            </a-radio>
          </a-radio-group>
        </a-col>
        <a-col :span="3">
          <!-- <a-input placeholder="请输入关联系统订单编号" v-model="form.changeOrderCode" /> -->
          <a-select
            v-show="form.changeProductFlag"
            :dropdownMatchSelectWidth="false"
            style="width: 100%"
            show-search
            v-model="form.changeOrderCode"
            placeholder="请输入订单编号"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="orderhandleSearch"
            @change="orderhandleChange"
          >
            <a-select-option v-for="item in orderList" :key="item.code">
              <template>
                {{ item|orderListSelect }}
              </template>
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </div>

    <div class="headTitle" v-show="form.payMethod != 6">
      <p>发票</p>
    </div>
    <a-row class="rows" v-show="form.payMethod != 6">
      <a-col class="left required" span="3">开票方式</a-col>
      <a-col span="4">
        <a-select placeholder="开票方式" style="width: 100%" v-model="form.canInvoice">
          <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
          <a-select-option :key="1" v-if="form.billingAgency == 2">线上开票</a-select-option>
          <a-select-option :key="0">线下开票</a-select-option>
        </a-select>
      </a-col>
      <a-col class="left required" span="3" v-if="form.canInvoice == 1 && form.billingAgency == 2">用户发票</a-col>
      <a-col span="4" offset="1" v-if="form.canInvoice == 1 && form.billingAgency == 2">
        <a-select placeholder="请选择用户发票" style="width: 100%" @change="changeInvoice" v-model="form.invoiceId">
          <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
          <!-- form.payMethod -->
          <template v-for="item in invoiceList">
            <a-select-option v-if="form.payMethod != 3 || item.headerType == 1" :key="item.userInvoiceId" :title="item.invoiceHeader">
              {{ item.headerType == 1?'企业':item.headerType == 3?'个人':'其他' }} - {{item.invoiceHeader}}
            </a-select-option>
          </template>
        </a-select>
      </a-col>
    </a-row>
    
    <div class="headTitle" v-if="form.canInvoice == 1 && form.billingAgency == 2">
      <p>新增发票</p>
    </div>
    <a-row class="rows" v-if="form.canInvoice == 1 && form.billingAgency == 2">
      <a-col class="left required" span="3">发票类型</a-col>
      <a-col span="4">
        <a-select placeholder="请选择发票类型" style="width: 100%" @change="changeHeaderType" v-model="invoiceForm.headerType">
          <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
          <a-select-option :key="1"> 企业</a-select-option>
          <a-select-option :key="3" v-if="form.payMethod != 3">个人/非企业单位</a-select-option>
        </a-select>
      </a-col>
      <a-col class="left required" span="3" v-show="invoiceForm.headerType">发票抬头</a-col>
      <a-col span="4">
        <a-input v-show="invoiceForm.headerType == 3" placeholder="请输入发票抬头" v-model="invoiceForm.invoiceHeader" />
        <a-select
          v-show="invoiceForm.headerType == 1"
          show-search
          v-model="invoiceForm.invoiceHeader"
          placeholder="请输入发票抬头"
          style="width: 200px"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="filterOption"
          :not-found-content="null"
          @search="headerSearch"
        >
          <a-select-option @click="headerChange(item.nsrmc)" v-for="item in invoiceHeaderData" :key="item.nsrmc||'def'">
            {{ item.nsrmc }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col class="left required" span="3" v-if="invoiceForm.headerType == 1">税号</a-col>
      <a-col span="4" v-if="invoiceForm.headerType == 1">
        <a-input placeholder="请输入税号" v-model="invoiceForm.dutyParagraph" />
      </a-col>
    </a-row>
    <a-row class="rows" style="padding-left: 100px;" v-if="form.canInvoice == 1 && form.billingAgency == 2">
      <a-button type="primary" class="btn" :loading="btnLoading" @click="addInvoiceData">保存</a-button>
      <span class="tips" style="margin-left: 20px; color: red;">提示：从用户的发票列表中选择的发票无须保存，若您修改了发票内容可继续点击保存按钮保存发票。</span>
    </a-row>

    <div class="headTitle">
      <p>备注</p>
    </div>
    <a-row class="rows">
      <a-col span="24">
        <a-textarea
          v-model="form.remark"
          placeholder="请输入备注"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-col>
    </a-row>
    <div class="button">
      <a-button type="primary" class="btn" v-if="!orderId" :loading="btnLoading" @click="submit" v-hasPermi="['appendorder:info:add']">确认</a-button>
      <a-button class="all_boder_btn btn" @click="$router.go(-1)">{{!orderId?'取消':'返回'}}</a-button>
    </div>
    <!-- 新建商品弹框 -->
    <a-modal
      width="600px"
      title="新建商品"
      :visible="addClassVisible"
      :confirm-loading="confirmLoading"
      :closable="false"
      okText="确认"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="onSubmit"
    >
      <div class="form">
        <p class="formTitle">商品信息: </p>
        <a-select placeholder="订单类型" style="width: 120px" v-model="form.orderType" @change="changeOrderType" :disabled="isDisabled()">
          <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
          <template v-if="form.userType == 1">
            <a-select-option :value="item.key" v-for="item in orderType" :key="item.key">{{item.value}}</a-select-option>
          </template>
          <template v-else>
            <a-select-option :value="item.id" v-for="item in orgOrderType" :key="item.id" v-show="orgOption(item.id)">{{item.name}}</a-select-option>
          </template>
        </a-select>

        <!-- 个人商品订单 模糊搜索 -->
        <a-select
          v-if="form.userType == 1 && form.orderType == 1"
          style="width: 300px"
          show-search
          v-model="modelForm.productId"
          placeholder="请选择商品"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="shophandleSearch"
          @change="selectProduct"
        >
          <a-select-option v-for="item in productList" :key="item.productId">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <!-- 非个人商品订单 下拉框 -->
        <a-select placeholder="请选择商品" style="width: 300px" @change="selectProduct" v-model="modelForm.productId" v-else>
          <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
          <!-- 个人 -->
          <template v-if="form.userType == 1 && form.orderType == 3">
            <a-select-option :value="item.configId" v-for="item in productList" :key="item.productId">{{item.name}}</a-select-option>
          </template>
          <template v-if="form.userType == 1 && form.orderType != 3">
            <a-select-option :value="item.productId" v-for="item in productList" :key="item.productId">{{item.name}}</a-select-option>
          </template>
          <!-- 机构-学习包 -->
          <template v-if="form.userType == 2 && form.orderType == -1">
            <a-select-option :value="item.id" v-for="item in productList" :key="item.id">{{item.name}}</a-select-option>
          </template>
          <template v-if="form.userType == 2 && form.orderType != -1">
            <a-select-option :value="item.productId" v-for="item in productList" :key="item.productId">{{item.productName}}</a-select-option>
          </template>
        </a-select>
      </div>

      <div class="form" v-if="openTimeList.length">
        <p class="formTitle">课程期数: </p>
        <a-select
          style="width: 420px"
          v-model="modelForm.openTimeId"
          placeholder="请选择课程期数"
        >
          <a-select-option
            :value="item.openTimeId"
            v-for="(item, index) in openTimeList"
            :key="index"
          >
            {{ item.className }}: {{ item.startTime }} - {{ item.endTime }}
          </a-select-option>
        </a-select>
      </div>
      <div class="form" v-if="numberShow()">
        <p class="formTitle">数量: </p>
        <a-input-number
          id="inputNumber"
          style="width: 120px"
          placeholder="请填写数量"
          v-model="modelForm.number"
          :formatter="value => `${value}`"
          :parser="value => value.replace('.', '')"
          :min="1" />
      </div>
      <div class="form" v-if="priceShow()">
        <p class="formTitle">单价: </p>
        <a-input-number
          id="inputNumber"
          style="width: 120px"
          placeholder="请填写单价"
          v-model="modelForm.couponPrice"
          :min="0" />
      </div>
    </a-modal>
    <!-- 选择收货信息弹框 -->
    <a-modal
      title="请选择收货地址"
      :width="400"
      :visible="changeAddressVisible"
      :confirm-loading="selctAddressLoading"
      @cancel="changeAddressVisible = false"
    >
      <template slot="footer">
        <a-button type="primary" :loading="btnLoading" @click="setSelectAddress()">保存</a-button>
        <a-button type="primary" @click="changeAddressVisible = false"
          >取消</a-button
        >
      </template>
      <div class="radios">
        <a-radio-group
          v-decorator="[
            'address',
            { rules: [{ required: true, message: '请选择收货地址' }] },
          ]"
        >
          <a-radio
            class="radios_xs"
            :value="item.id"
            v-for="item in addressList"
            :key="item.id"
            @click="fetchAddreddObj(item)"
          >
            <span>{{ item.consignee }}  {{ item.phone }}<br/>{{ item.proviceName }}{{ item.cityName }}{{ item.areaName }}{{ item.address }}</span>
          </a-radio>
        </a-radio-group>
      </div>
    </a-modal>
    <!-- 标记渠道方 -->
    <a-modal
      title="标记渠道方"
      :maskClosable="true"
      :closable="true"
      :visible="markVisible"
      @cancel="markVisible = false"
    >
      <div>
        <a-select
          placeholder="请选择渠道方"
          v-model="channelCode"
          style="width:100%"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="item.channelCode" v-for="item in channelData" :key="item.id">{{item.channelName}}</a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onBatchMark"
          >确认</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import Province from "@/components/provinceCity";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    fixed: "left",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品名称",
    align: "center",
    width: 300,
    dataIndex: "name"
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productType",
    scopedSlots: { customRender: "productType" },
  },
  {
    title: "单价",
    align: "center",
    dataIndex: "couponPrice"
  },
  {
    title: "数量",
    align: "center",
    dataIndex: "number",
  },
  {
    title: "是否为赠品",
    align: "center",
    dataIndex: "isGift",
    scopedSlots: { customRender: "isGift" },
  },
  {
    title: "渠道方",
    align: "center",
    dataIndex: "channelName",
    scopedSlots: { customRender: "channelName" },
  },
  {
    title: "金额",
    align: "center",
    dataIndex: "payPrice",
    scopedSlots: { customRender: "payPrice" },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    fixed: "right",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  components: { Province },
  data() {
    return {
      radioStyle: {  // 单项 垂直分布
        height: '30px',
        lineHeight: '30px',
      },
      addressList: [], // 收货地址
      orderId: null,
      type: null,
      columns,
      tableData: [], // 商品列表
      modelkeyword: '',
      userList: [],
      handleIndex: null,
      btnLoading: false, // 新建按钮loading
      authentication: '', // 用户是否已认证
      form: {
        userId: undefined,
        userType: '1',
        orderType: undefined,
        payMethod: undefined, // 支付方式
        payPrice: '' , // 订单总额
        remark: '', // 备注
        tradeId: '', // 支付交易号
        paymentTime: '', // 支付时间
        payVoucher: '', // 上传凭证
        canInvoice: undefined, // 开票方式
        invoiceId: undefined, // 发票id
        billingAgency: undefined, // 收款方，1、元道2、出版社
        receivingSource: undefined, // 渠道来源
        changeProductFlag: 0, // 是否为换课订单
        changeOrderCode: undefined, // 被换课订单编号
        receiptAmount: undefined, // 财务入账金额（多个订单共用）
      },
      loadingPicture: false,
      // 个人订单类型
      orderType:[
        {
          key: 1,
          value: '商品订单'
        },{
          key: 2,
          value: '电子照订单'
        },{
          key: 3,
          value: '补考订单'
        },{
          key: 4,
          value: '延期订单'
        }
      ],
      orgOrderType: [], // 机构订单类型
      payType: [
        {
          key: 1,
          value: '微信'
        },{
          key: 2,
          value: '支付宝'
        },{
          key: 3,
          value: '对公转账'
        },{
          key: 6,
          value: '赠品'
        }
      ],

      // 新建弹框字段
      shopKeyWord: '', // 个人-商品订单模糊搜索
      addClassVisible: false,
      changeAddressVisible: false,
      confirmLoading: false,
      openTimeLoading: false,
      selctAddressLoading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      modelForm: {},
      productList: [],
      openTimeList: [],

      // 地址数据
      addressObj: {
        // 发货省市区
        shippingAddressValue: [],

        // 新建时数据
        provice: null,
        city: null,
        area: null,
        address: "",
        phone: "",
        consignee: "",
        isDefault: 0
      },

      invoiceList: [],
      invoiceForm: {
        headerType: undefined, // 发票类型
        invoiceHeader: undefined, // 抬头
        dutyParagraph: '', // 税号
      },

      invoiceHeaderData: [{}], // 发票抬头列表
      orderList: [], // 订单列表 - 换课订单绑定

      markVisible:false, // 控渠道选择窗显隐
      channelIndex:0, 
      channelData:[], // 渠道方列表
      channelCode:undefined,
    }
  },
  filters: {
    orderListSelect(e) {
      // 订单编号 支付时间 支付方式  支付金额  订单状态  订单创建类型
      let code = e.code, // 订单编号
          time = e.paymentTime, // 支付时间
          payMethod = '', // 支付方式
          payPrice = '￥' + e.payPrice, // 支付金额
          status = '', // 订单状态
          orderCreateType = e.orderCreateType == 1? '线上订单' : '订单补录'; // 订单创建类型
        
      // 1待付款、2待发货、3待收货、4.部分发货、5交易完成、6交易关闭
      switch(e.status) {
        case 1:
        status = '待付款';
        break;
        case 2:
        status = '待发货';
        break;
        case 3:
        status = '待收货';
        break;
        case 4:
        status = '部分发货';
        break;
        case 5:
        status = '交易完成';
        break;
        case 6:
        status = '交易关闭';
      }

      // 1.微信 2.支付宝 3.对公转账 4.兑换券 6.赠品
      switch(e.payMethod) {
        case 1:
        payMethod = '微信';
        break;
        case 2:
        payMethod = '支付宝';
        break;
        case 3:
        payMethod = '对公转账';
        break;
        case 4:
        payMethod = '兑换券兑换';
        break;
        case 6:
        payMethod = '赠品';
        break;
      }
      
      return code+'/'+time+'/'+payMethod+'/'+payPrice+'/'+status+'/'+orderCreateType;
    }
  },
  created() {
    // this.getCurrentTime();
    this.getTypeList();
    this.orderId = this.$route.query.orderId;
    this.type = this.$route.query.type;
    if(this.$route.query.userId) {
      this.getUserInvoiceList(this.$route.query.userId);
    }
    if(this.orderId) {
      this.$set(this,'payType',[
        {
          key: 1,
          value: '微信'
        },{
          key: 2,
          value: '支付宝'
        },{
          key: 3,
          value: '对公转账'
        },{
          key: 6,
          value: '赠品'
        }
      ])
      if(this.type == 1) {
        this.getOrderDetail(this.orderId);
      }else {
        this.getOrgOrderDetail(this.orderId);
      }
    }
    this.getChannelList()
  },
  methods: {
    // 发票类型修改 - 清空已填信息
    changeHeaderType() {
      this.$set(this.invoiceForm,'invoiceHeader',undefined);
      this.$set(this.invoiceForm,'dutyParagraph','');
    },
    // 抬头搜索
    headerSearch(value) {
      if(value.length < 6) {
        this.invoiceHeaderData = [{}];
        return
      }
      this.$ajax({
        url: '/hxclass-management/invoice/getCompanyInfo',
        params: {
          nsrmc: value
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.invoiceHeaderData = res.data;
        }else{
          this.invoiceHeaderData = [{}];
        }
      })
    },
    headerChange(value) {
      this.invoiceHeaderData.some(item=>{
        if(item.nsrmc == value) {
          this.invoiceForm.dutyParagraph = item.nsrsbh;
          return true;
        }
      })
      this.invoiceHeaderData = [{}];
    },
    filterOption(input, option) {
      this.$set(this.invoiceForm,'invoiceHeader',input)
      // 在此前是可以手动输入，但鼠标离开后，输入的内容不能在输入框内保存，将input的值给到a-select标签的v-model绑定的newSoftName即可实现将手动输入的值赋值给input
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    // 收款方改变
    billingChange(e) {
      if(e == 1) {
        this.$set(this.form,'canInvoice',0);
      }
    },

    // 选择发票
    changeInvoice(e) {
      this.invoiceList.map(item=>{
        if(item.userInvoiceId == e) {
          this.$set(this.invoiceForm,'headerType',item.headerType)
          this.$set(this.invoiceForm,'invoiceHeader',item.invoiceHeader)
          this.$set(this.invoiceForm,'dutyParagraph',item.dutyParagraph)
        }
      })
    },
    // 查询用户已有发票列表
    getUserInvoiceList(userId) {
      this.$ajax({
        url: '/hxclass-management/invoice/header/useList',
        params: {
          userId
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.invoiceList = res.data;
        } else {
          this.invoiceList = [];
          this.$message.error(res.message);
        }
      })
    },

    // 新建抬头
    addInvoiceData() {
      if(!this.form.userId) {
        this.$message.warning("请选择用户");
        return;
      }
      if (!this.invoiceForm.headerType) {
        this.$message.warning("请选择发票类型");
        return;
      }
      if (!this.invoiceForm.invoiceHeader) {
        this.$message.warning("请填写发票抬头");
        return;
      }
      if (this.invoiceForm.headerType == 1 && !this.invoiceForm.dutyParagraph) {
        this.$message.warning("请填写税号");
        return;
      }
      let _this = this;
      // this.$confirm({
      //   title: "从用户的发票列表中选择的发票无须保存，若您修改了发票内容可继续点击确认按钮保存发票。",
      //   okText: "确认",
      //   onOk() {
          _this.btnLoading = true;
          let obj = JSON.parse(JSON.stringify(_this.invoiceForm));
          obj['userId'] = _this.form.userId;
          _this.$ajax({
            url: '/hxclass-management/invoice/header/insert',
            method: "post",
            params: obj
          }).then(res=>{
            _this.btnLoading = false;
            if(res.code == 200 && res.success) {
              _this.$set(_this.form,'invoiceId',res.data);
              _this.getUserInvoiceList(_this.form.userId);
            } else {
              _this.$message.error(res.message);
            }
          })
      //   },
      //   onCancel() {},
      // });
    },

    // 新增/编辑
    pushData() {
      if (!this.addressObj.consignee) {
        this.$message.warning("请填写收货人");
        return;
      }
      if (!this.addressObj.phone) {
        this.$message.warning("请填写手机号码");
        return;
      }
      if (!this.$regular.phone.reg.test(this.addressObj.phone)) {
        this.$message.warning(this.$regular.phone.msg);
        return;
      }
      if (!this.addressObj.shippingAddressValue?.length) {
        this.$message.warning("请选择收货地址");
        return;
      }
      if (!this.addressObj.address) {
        this.$message.warning("请填写详细地址");
        return;
      }
      this.addressObj.userId = this.form.userId;
      this.btnLoading = false
      let urls = "";
      let method = "";
      urls = "/hxclass-management/ship/address/add";
      method = "POST";
      this.PreventLoad = true;
      this.$ajax({
        url: urls,
        method: method,
        params: this.addressObj,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.$set(this.form,'receiveId',res.data)
          this.btnLoading = false;
        } else {
          this.$message.error("操作失败");
          this.btnLoading = false;
        }
        this.PreventLoad = false;
      });
    },
    // 查询收货地址
    getAddressList() {
      this.$ajax({
        url: "/hxclass-management/ship/address/getUseList",
        params: {
          userId: this.form.userId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.addressList = res.data;
          console.log(this.addressList);
          if (this.addressList.length) {
            this.changeAddressVisible = true;
          } else {
            this.$message.error("暂无收货地址");
          }
        }
      });
    },
    // 选择收货地址
    fetchAddreddObj(e) {
      this.addressObj = {
        // 发货省市区
        shippingAddressValue: [e.provice, e.city, e.area],
        provice : e.provice,
        city : e.city,
        area : e.area,
        address: e.address,
        phone: e.phone,
        consignee: e.consignee,
        receiveId: e.id
      }
      // this.$refs.childProvince.FunValue();
    },
    // 设置选择的收货地址
    setSelectAddress() {
      this.$message.success("操作成功");
      this.$refs.childProvince.FunValue();
      this.changeAddressVisible = false;
      this.$set(this.form,'receiveId',this.addressObj.receiveId)
      this.btnLoading = false;
      this.PreventLoad = false;
    },
    // 获取发货省市区编码
    FunProvince(data) {
      this.addressObj.provice = data[0];
      this.addressObj.city = data[1];
      this.addressObj.area = data.length > 2 ? data[2] : "";

      this.addressObj.shippingAddressValue = data;
    },

    // 获取商品关联商品
    getGiftList(e,number) {
      this.$ajax({
        url: "/hxclass-management/product-archives/archives-product",
        method: "get",
        params: {
          productId: e
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          if(res.data.length){
            let arr = res.data.map(item=>{
              return {
                isPackage: false, // 是否机构包
                number: number, // 数量
                openTimeId: undefined, //	开课时间id
                productId: item.productId, //	商品id
                signupUserId: '',
                productType: item.type, //	商品类型
                name: item.name, //	商品名称
                couponPrice: item.couponPrice, // 单价
                isGift: 1,
                pid: e
              }
            })
            this.tableData.push(...arr)
          }
        }
      })
    },
    // {
    //     isPackage: this.form.orderType == -1?true:false, // 是否机构包
    //     number: 1, // 数量
    //     openTimeId: undefined, //	开课时间id
    //     productId: e, //	商品id
    //     signupUserId: arr[0].signupUserId,
    //     productType: this.form.userType == 1?arr[0].type:arr[0].productType, //	商品类型
    //     name: this.form.userType == 1 || this.form.orderType == -1?arr[0].name:arr[0].productName, //	商品名称
    //     couponPrice: this.form.userType == 1 && this.form.orderType != 1?this.form.orderType == 2?20:0:arr[0].couponPrice // 单价
    //   }
    
    changePayMethod(e) {
      // 赠品
      if(e == 6) {
        this.form.payPrice = 0;
        this.form.canInvoice = undefined;
      }
      // 对公转账
      else if(e == 3) {
        this.form.payPrice = '';
        // 若已选发票类型为个人 清空发票内容
        if(this.invoiceForm.headerType) {
          this.$set(this.form,"invoiceId",undefined);
          this.invoiceForm = {
            headerType: undefined, // 发票类型
            invoiceHeader: undefined, // 抬头
            dutyParagraph: '', // 税号
          }
        }
      }else{
        this.form.payPrice = '';
      }
    },
    // 个人订单详情
    getOrderDetail(e) {
      this.$ajax({
        url: "/hxclass-management/order/detail/replenishment/" + this.orderId,
        method: "get",
      }).then(async (res) => {
        if (res.code == 200 && res.success) {
          this.modelkeyword = res.data.phone;
          this.form.userId = Number(this.$route.query.userId);
          await this.getUserList();
          this.form.orderType = res.data.orderType;
          this.form.payMethod = res.data.payMethod;
          this.form.payPrice = res.data.payPrice;
          this.form.paymentTime = res.data.paymentTime;
          this.form.receivingSource = res.data.receivingSource;
          this.form.remark = res.data.remark;
          this.form.tradeId = res.data.tradeId;
          this.form.payVoucher = res.data.payVoucher; // 支付凭证
          this.form.changeProductFlag = res.data.changeProductFlag; // 是否为换课订单
          this.form.changeOrderCode = res.data.changeOrderCode; // 被换课订单编号
          this.form.canInvoice = res.data.canInvoice; // 开票方式 1 线上 0 线下
          this.form.invoiceId = res.data.invoiceId; // 发票id
          this.form.billingAgency = res.data.billingAgency; // 收款方
          this.form.receiptAmount = res.data.receiptAmount; // 财务入账金额（多个订单共用）
          this.changeInvoice(res.data.invoiceId); // 发票信息回显
          let arr = res.data.productList.map(item=>{
            return {
              number: item.productCount, // 数量
              channelName: item.channelName, // 渠道方
              productId: item.productId, //	商品id
              productType: item.productType, //	商品类型
              name: item.name, //	商品名称
              couponPrice: item.productPrice, // 单价
              type: item.type, // 1 正常 2 赠品
              payPrice: item.payPrice // 维护金额
            }
          })
          if(res.data.shipAddress){
            this.addressObj = {
              // 发货省市区
              shippingAddressValue: [res.data.shipAddress.province,res.data.shipAddress.city,res.data.shipAddress.district],
              address: res.data.shipAddress.address,
              phone: res.data.shipAddress.phone,
              consignee: res.data.shipAddress.name
            }
          }
          this.tableData = arr;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 机构订单详情
    getOrgOrderDetail(e) {
      this.$ajax({
        url: "/hxclass-management/order/org/detail/personal/" + this.orderId,
        method: "get",
      }).then(async (res) => {
        if (res.code == 200 && res.success) {
          this.form.userType = this.type;
          this.modelkeyword = res.data.userName;
          this.form.userId = Number(this.$route.query.userId);
          await this.getUserList();
          this.form.orderType = res.data.orderType;
          this.form.payMethod = res.data.payMethod;
          this.form.payPrice = res.data.payPrice;
          this.form.paymentTime = res.data.paymentTime;
          this.form.receivingSource = res.data.receivingSource;
          this.form.remark = res.data.remark;
          this.form.tradeId = res.data.tradeId;
          this.form.payVoucher = res.data.payVoucher; // 支付凭证
          this.form.changeProductFlag = res.data.changeProductFlag; // 是否为换课订单
          this.form.changeOrderCode = res.data.changeOrderCode; // 被换课订单编号
          this.form.canInvoice = res.data.canInvoice; // 开票方式 1 线上 0 线下
          this.form.invoiceId = res.data.invoiceId; // 发票id
          this.form.billingAgency = res.data.billingAgency; // 收款方
          this.form.receiptAmount = res.data.receiptAmount; // 财务入账金额（多个订单共用）
          this.changeInvoice(res.data.invoiceId); // 发票信息回显

          // 回显商品列表
          let arr = [];
          res.data.orgOrderProductVOS.map(item=>{
            if(item.productType == 2){
              this.form.orderType = -1;
            }
            // 商品为学习包
            if(item.productType == 2) {
              arr.push({
                number: item.productCount, // 数量
                productId: item.productId, //	商品id
                productType: item.productType, //	商品类型
                name: item.productName, //	商品名称
                couponPrice: item.productPrice, // 单价
                type: item.type, // 1 正常 2 赠品 - 一层未返
              })
            }
            
            item.productList.map(childitem=>{
              arr.push({
                number: childitem.productCount, // 数量
                productId: childitem.productId, //	商品id
                productType: childitem.productType, //	商品类型
                name: childitem.name, //	商品名称
                couponPrice: childitem.productPrice, // 单价
                type: childitem.type, // 1 正常 2 赠品 - 一层未返
                payPrice: item.payPrice // 手动维护金额
              })
            })
          })

          if(res.data.shipAddress){
            this.addressObj = {
              // 发货省市区
              shippingAddressValue: [res.data.shipAddress.province,res.data.shipAddress.city,res.data.shipAddress.district],
              address: res.data.shipAddress.address,
              phone: res.data.shipAddress.phone,
              consignee: res.data.shipAddress.name
            }
          }
          this.tableData = arr;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 商品删除
    delShop(e,id) {
      this.tableData.splice(e,1);
      let arr = this.tableData.filter(item=>{
        return item.pid != id;
      })
      this.$set(this,'tableData',arr);
    },
    onBatchMark(){
      if(!this.channelCode){
        return this.$message.warning("请选择渠道方");
      }
      this.channelData.forEach(element => {
        if(element.channelCode == this.channelCode){
          this.$set(this.tableData, this.channelIndex, { ...this.tableData[this.channelIndex], channelCode: element.channelCode, channelName: element.channelName });
        }
      });
      this.markVisible = false
      this.channelCode = undefined
    },
    // 设为赠品
    setGift(i) {
      if(this.tableData[i].type == 2) {
        return this.$set(this.tableData[i],'type',1)
      }
      this.$set(this.tableData[i],'type',2)
    },
    // 子商品价格维护
    priceChange(value, item) {
      
      this.tableData.forEach(element => {
        if (element.productId == item.productId) {
          this.$set(element,'payPrice',value)
          if (value <= 0) {
            this.$delete(element, 'payPrice', value);
          }
        }
      });
      // this.$set(this.tableData[i],'type',1)
    },
    // 修改商品
    editShop(e) {
      this.handleIndex = e;
      this.modelForm = JSON.parse(JSON.stringify(this.tableData[e]));
      this.addClassVisible = true;
    },
    addShop() {
      if(!this.form.userId) {
        return this.$message.error('请选择用户')
      }
      this.addClassVisible = true;
    },
    // 数量是否展示
    numberShow() {
      if(!this.modelForm.productId) {
        return false;
      }
      if(this.form.userType == 1) {
        if(this.form.orderType == 1) {
          if(this.modelForm.productType == 1 || this.modelForm.productType == 4){
            if (this.modelForm.productType == 1 && this.modelForm.buyLimitNum == 1) {
              return true;
            }
            return false;
          }else{
            return true;
          }
        }else{
          return false;
        }
      }else{
        return true;
      }
    },
    // 单价是否展示
    priceShow() {
      if(!this.modelForm.productId) {
        return false;
      }
      if(this.form.userType == 1 && this.form.orderType != 1 && this.form.orderType != 2) {
        return true;
      }
      return false;
    },
    // 订单类型是否禁用
    isDisabled() {
      if(this.tableData.length) {
        if(this.form.userType == 1){
          return true;
        }else{
          return false;
        }
      }else {
        return false;
      }


      // return false;

    },
    // 机构端类型是否展示
    orgOption(e) {
      if(this.tableData.length) {
        if(this.form.orderType == -1 && e != -1) {
          return false;
        }
        if(this.form.orderType != -1 && e == -1) {
          return false;
        }
        return true;
      }else{
        return true;
      }
    },
    // 返回商品类型
    retproductType(e) {
      if(this.form.userType == 1) {
        if(this.form.orderType == 1) {
          if(e == 1) {
            return '课程'
          }else if(e == 2) {
            return '图书'
          }else if(e == 3) {
            return '教具'
          }else if(e == 4) {
            return '模拟考试'
          }
        }else if(this.form.orderType == 2) {
          return '电子照片'
        }else if(this.form.orderType == 3) {
          return '补考'
        }else if(this.form.orderType == 4) {
          return '延期'
        }
      }else{
        if(this.form.orderType == -1) {
          return '学习包'
        }else{
          if(e == 1) {
            return '课程'
          }else if(e == 2) {
            return '图书'
          }else if(e == 3) {
            return '教具'
          }else if(e == 4) {
            return '模拟考试'
          }
        }
      }
    },

    // 上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },
    // 上传图片
    uploadPicture(fileData) {
      this.loadingPicture = true;
      this.$set(this.form, "payVoucher", 1);
      const bucket = 'user/transaction/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.$set(this.form, "payVoucher",url);
        } else {
          this.$set(this.form, "payVoucher", '');
          this.$message.error("上传失败");
        }
        this.loadingPicture = false;
      });
    },
    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth()+1<10 ? '0'+(new Date().getMonth()+1) : new Date().getMonth()+1;
      let dd = new Date().getDate()<10 ? '0'+new Date().getDate() : new Date().getDate();
      let hh = new Date().getHours()<10 ? '0'+new Date().getHours() : new Date().getHours();
      let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
      _this.form.paymentTime = yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss;
    },

    // 个人-商品订单模糊搜索
    shophandleSearch(e) {
      this.shopKeyWord = e;
      this.productList = [];
      this.changeOrderType(this.form.orderType);
    },
    handleSearch(e) {
      this.modelkeyword = e;
      this.userList = [];
      this.getUserList();
    },
    handleChange(e) {
      this.addressObj.userId = e;

      // 存储用户认证状态
      this.userList.map(item=>{
        if(item.userId == e) {
          this.authentication = item.authentication;
        }
      })

      this.form.orderType = undefined;
      this.tableData = [];

      // 查询用户已有发票列表
      this.form.invoiceId = undefined;
      this.getUserInvoiceList(e);
    },

    // 订单编号查询订单
    orderhandleSearch(e) {
      this.orderList = [];
      this.getOrder(e);
    },
    orderhandleChange(e) {
      // console.log(e,'orderhandleChange')
    },
    // 获取订单信息
    getOrder(e) {
      this.$set(this.form,'changeOrderCode',undefined)
      if(e.length < 10) return
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        let params = {
          code: e
        };
        this.$ajax({
          url: "/hxclass-management/order/detail/code",
          method: "get",
          params
        }).then((res) => {
          if (res.code == 200 && res.success) {
            if(!res.data){
              this.$message.error('未搜到相关订单');
            }else{
              this.orderList.push(res.data);
              this.$set(this.form,'changeOrderCode',e)
            }
          } else {
            this.$message.error(res.message);
          }
        });
      }, 1000);
    },

    // 查询用户列表
    getUserList() {
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        if (!this.modelkeyword){
          return;
        }
        let params = {
          type: this.form.userType
        };
        if(this.form.userType == 1) {
          if(!isNaN(this.modelkeyword)) {
            if (!this.$regular.phone.reg.test(this.modelkeyword)) {
              this.$message.warning(this.$regular.phone.msg);
              return;
            }
            params.mobile = this.modelkeyword;
          }else{
            params.name = this.modelkeyword;
          }
        }else{
          if(!isNaN(this.modelkeyword)) {
            params.code = this.modelkeyword;
          }else{
            params.name = this.modelkeyword;
          }
        }
        this.$ajax({
          url: "/hxclass-management/user/info/user/info",
          method: "get",
          params
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.userList = res.data;
            if(!res.data.length){
              this.$message.error('未搜到相关用户');
            }
          } else {
            this.$message.error(res.message);
          }
        });
      }, 1000);
    },

    // 选择弹框商品
    selectProduct(e) {
      let arr = this.productList.filter(item=>{
        if(this.form.userType == 2 && this.form.orderType == -1){
          return item.id == e;
        }
        if(this.form.userType == 1 && this.form.orderType == 3){
          return item.configId == e;
        }
        return item.productId == e;
      })
      this.modelForm = {
        isPackage: this.form.orderType == -1?true:false, // 是否机构包
        number: 1, // 数量
        openTimeId: undefined, //	开课时间id
        productId: e, //	商品id
        signupUserId: arr[0].signupUserId,
        productType: this.form.userType == 1?arr[0].type:arr[0].productType, //	商品类型
        name: this.form.userType == 1 || this.form.orderType == -1?arr[0].name:arr[0].productName, //	商品名称
        couponPrice: this.form.userType == 1 && this.form.orderType != 1?this.form.orderType == 2?20:0:arr[0].couponPrice, // 单价
        type: 1,
        buyLimitNum: arr[0].buyLimitNum,
      }

      // 存储机构端学习包商品是否含有实物
      if(this.form.userType == 2 && this.form.orderType == -1) {
        this.modelForm.freightSet = arr[0].freightSet
      }else {
        this.modelForm.freightSet = undefined
      }

      this.openTimeList = [];
      if(this.form.userType == 1 && this.modelForm.productType == 1) {
        this.getOpenTimeList(arr[0].courseId);
      }
    },

    // 修改用户类型-重置信息
    changeUserType() {
      this.form.payMethod = undefined;
      this.form.payPrice = '';
      if(this.form.userType == 1) {
        this.$set(this,'payType',[
          {
            key: 1,
            value: '微信'
          },{
            key: 2,
            value: '支付宝'
          },{
            key: 3,
            value: '对公转账'
          },{
            key: 6,
            value: '赠品'
          }
        ])
      }else{
        this.$set(this,'payType',[
          {
            key: 1,
            value: '微信'
          },{
            key: 2,
            value: '支付宝'
          },{
            key: 3,
            value: '对公转账'
          },{
            key: 6,
            value: '赠品'
          }
        ])
      }
      this.form.orderType = undefined;
      this.form.userId = undefined;
      this.authentication = undefined;
      this.userList = [];
      this.productList = [];
      this.tableData = [];
      this.modelForm = {};
    },

    // 修改订单类型
    changeOrderType(e) {
      this.modelForm = {};
      this.productList = [];
      if(this.form.userType == 1) {
        this.getProductList(e);
      }else {
        if(e == -1) {
          this.getOrgPackageList();
        }else{
          this.getOrgProductList(e);
        }
      }
    },
    // 获取机构端类型列表
    getTypeList() {
      this.$ajax({
        url: "/hxclass-management/organiz-product-categroy/tree",
        params: {
          pid: 0,
          type: 1
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let arr = res.data.filter(item=>{
            return item.publishFlag;
          })
          // this.orgOrderType = [{id:'-1',name: '学习包'},...arr];
          this.orgOrderType = arr;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取机构端商品列表
    getOrgProductList(e) {
      this.$ajax({
        url: "/hxclass-management/organiz-product/mall/list",
        params: {
          category: e,
          pageNum: 1,
          pageSize: 1000
        }
      }).then((res) => {
        this.productList = res.records;
      })
    },
    // 获取机构端学习包列表
    getOrgPackageList() {
      this.$ajax({
        url: "/hxclass-management/organiz-package/mall-list",
        params: {
          pageNum: 1,
          pageSize: 1000
        }
      }).then((res) => {
        this.productList = res.records;
      })
    },
    // 获取个人端商品列表
    getProductList(e,name) {
      this.$ajax({
        url: "/hxclass-management/product-archives/getRepairProductList",
        params: {
          type: e,
          userId: this.form.userId,
          productName: this.shopKeyWord,
          authentication: this.authentication
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.productList = res.data;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 获取课程班期列表
    getOpenTimeList(e) {
      this.openTimeLoading = true;
      this.$ajax({
        url: "/hxclass-management/course/open-time/select",
        method: "get",
        params: {
          courseId: e,
        },
      }).then((res) => {
        this.openTimeLoading = false;
        if (res.code == 200 && res.success) {
          this.openTimeList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleCancel() {
      this.shopKeyWord = ''; // 个人-商品订单模糊搜索
      this.addClassVisible = false;
      this.confirmLoading = false;
      this.openTimeLoading = false;
      this.modelForm = {};
      this.openTimeList = [];
      this.addClassVisible = false;
    },
    onSubmit() {
      if(!this.form.orderType){
        return this.$message.warn('请选择订单类型')
      }else if(!this.modelForm.productId){
        return this.$message.warn('请选择商品')
      }else if(this.openTimeLoading){
        return this.$message.warn('请稍后重试')
      }else if(this.openTimeList.length && !this.modelForm.openTimeId){
        return this.$message.warn('请选择班期')
      }else if(this.priceShow() && !this.modelForm.couponPrice){
        return this.$message.warn('请填写单价')
      }
      if((this.form.userType == 1 && this.modelForm.productType == 1) || (this.form.userType == 2 && this.modelForm.productType != -1)){
        this.getGiftList(this.modelForm.productId,this.modelForm.number);
      }
      this.confirmLoading = true;
      this.modelForm.channelName = ""
      this.modelForm.channelCode = ""
      this.tableData.push(this.modelForm)
      this.addClassVisible = false;
      this.confirmLoading = false;
      this.openTimeLoading = false;
      this.modelForm = {};
      this.openTimeList = [];
      this.addClassVisible = false;
      this.shopKeyWord = '';
      this.getProductList(this.form.orderType);
      // this.form.orderType = undefined;
      // this.productList = []
    },
    submit() {
      if(!this.form.userId){
        return this.$message.warn('请选择用户')
      }else if(!this.tableData.length){
        return this.$message.warn('请选择商品')
      }else if(!this.form.payMethod){
        return this.$message.warn('请选择支付方式')
      }else if(this.form.payPrice === ''){
        return this.$message.warn('请填写订单总额')
      }else if(this.form.payMethod != 6 && !this.form.payPrice){
        return this.$message.warn('请填写订单总额')
      }else if(this.form.payPrice > this.allPrice){
        return this.$message.warn('订单总额不能大于应付金额！')
      }else if(!this.form.paymentTime){
        return this.$message.warn('请选择支付时间')
      }else if(this.havebooks && !this.form.receiveId) {
        return this.$message.warn('请输入并保存收货地址!')
      }else if(this.form.canInvoice === undefined && this.form.payMethod != 6){
        return this.$message.warn('请选择开票方式!')
      }else if(!this.form.billingAgency){
        return this.$message.warn('请选择收款方!')
      }else if(this.form.changeProductFlag && !this.form.changeOrderCode){
        return this.$message.warn('请输入被换课订单编号!')
      }else if(this.form.canInvoice == 1 && !this.form.invoiceId) {
        return this.$message.warn('请选择或保存发票后提交!')
      }else if (this.form.payMethod == 3 && !this.form.billingAgency) {
        return this.$message.warning("请填写转账户名");
      }
      
      this.btnLoading = true;
      let arr = this.tableData.filter(item=>{
        return !item.isGift;
      })
      arr = arr.map(item=>{
        return {
          channelCode: (item.channelCode ? item.channelCode : undefined), // 渠道码
          channelType: item.channelCode ? 1 : undefined, // 渠道类型 1、机构 2、活动
          isPackage: item.isPackage, // 是否机构包
          number: item.number, // 数量
          openTimeId: item.openTimeId, // 开课时间id
          productId: item.productId, // 商品id
          productType: item.productType,
          signupUserId: item.signupUserId, // 考生id
          type: item.type,
          payPrice: (item.payPrice >= 0 ? item.payPrice : undefined)// 手动维护的商品金额
        }
      })
      console.log("-=-=-=-=-=-=-=-=-" + JSON.stringify(arr));
      // return
      let params = {
        orderType: this.form.userType == 1?this.form.orderType:1, // 订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
        payMethod: this.form.payMethod, // 支付方式 支付方式1.微信 2.支付宝 3.对公转账 4.苹果内购支付5是线下支付 6是赠品
        payPrice: this.form.payPrice, // 收款金额
        paymentTime: this.form.paymentTime, // 支付时间
        receivingSource: this.form.receivingSource, // 渠道来源
        changeProductFlag: this.form.changeProductFlag, // 是否为换课订单
        changeOrderCode: this.form.changeProductFlag?this.form.changeOrderCode:'', // 被换课订单编号
        productList: arr, //	商品列表
        remark: this.form.remark, // 备注
        tradeId: this.form.tradeId, // 支付交易号
        userId: this.form.userId, // 用户id
        userType: this.form.userType, // 用户类型 , 1个人2机构
        canInvoice: this.form.canInvoice, // 开票方式 1 线上 0 线下
        isInvoice: this.form.canInvoice, // 0不开发票1开发票
        payVoucher: this.form.payVoucher,
        receiveId: this.havebooks?this.form.receiveId: '', // 收货地址id
        invoiceId: this.form.canInvoice?this.form.invoiceId || '':'', // 发票id
        billingAgency: this.form.billingAgency, // 收款方，1、元道2、出版社	
        receiptAmount: this.form.receiptAmount, // 财务入账金额（多个订单共用）
        accountName: this.form.payMethod == 3 ? this.form.accountName:null, // 对公转账账号
      }

      // 若收款方为元道 这两个字段不传
      if(this.form.billingAgency == 1) {
        delete params.isInvoice;
        delete params.invoiceId;
      }

      // 赠品补录发票相关字段处理 - 不开票
      if(this.form.payMethod == 6) {
        params.isInvoice = 0;
        params.canInvoice = 0;
        delete params.invoiceId;
      }

      this.$ajax({
        url: "/hxclass-management/order/supplement",
        method: "post",
        params
      }).then((res) => {
        this.openTimeLoading = false;
        if (res.code == 200 && res.success) {
          this.$router.go(-1);
        } else {
          this.btnLoading = false;
          this.$message.error(res.message);
        }
      });
    },
    // 获取渠道方
    getChannelList(){
      this.$ajax({
        method: 'get',
        url: '/hxclass-management/channel/login/list',
        params: {
          pageNum: 1,
          pageSize: 100,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.channelData = res.data.records
        }
      })
    },
  },
  computed: {
    // 计算商品总价
    allPrice() {
      let price = 0;
      this.tableData.map(item=>{
        price += item.couponPrice*item.number;
      })
      return price;
    },

    // 判断是否包含实物
    havebooks() {
      return this.tableData.some(item=>{
        if(this.form.userType == 2 && this.form.orderType == -1 && !this.orderId) {
          if(item.isPackage) return false
          return item.productType == 2 || item.productType == 3
        } else if(this.form.userType == 2 && this.orderId) {
          if(!item.type) return false
          return item.productType == 2 || item.productType == 3
        } else {
          return item.productType == 2 || item.productType == 3
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
  .headTitle{
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
    p{
      font-size: 16px;
      color: #333333;
      line-height: 16px;
    }
    .func{
      font-size: 12px;
      color: @theme;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .rows{
    margin-top: 20px;
    .left{
      text-align: right;
      height: 32px;
      line-height: 32px;
      &.required::before{
        content: '*';
        color: red;
        margin-right: 2px;
      }
      &::after{
        content: '：';
      }
    }
  }
  .cccText{
    color: #999999;
  }
  .form{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .formTitle{
      width: 100px;
      text-align: right;
      padding-right: 10px;
    }
  }
  .button {
    margin-top: 20px;
    .btn {
      margin-left: 20px;
    }
  }
  /deep/.ant-table-footer {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      align-items: center;
      p {
        font-size: 16px;
        color: #333333;
        line-height: 22px;
      }
      p:last-child {
        // margin-left: 40px;
      }
      .price {
        color: rgba(255, 78, 0, 1);
        font-weight: bold;
      }
    }
  }
  .radios {
  margin-left: 6px;
  margin-top: 8px;
  display: block;
  overflow: hidden;
  max-height: 500px;
  overflow-y: auto;
  span {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
  }
  .radios_xs {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }
  .tips{
    margin-left: 20px;
    font-weight: bold;
    color: red;
}
}
</style>